<template>
    <div class="tags">
      <div class="radio-buttons">
        <div class="radio-buttons-label">Wybierz język:</div>
      <label v-for="option in options" :key="option.value" class="radio-button">
        <input type="radio" :value="option.value" v-model="selectedOption" @change="handleChange" />
        <span class="checkmark"></span>
        {{ option.label }}
      </label>
      </div>

      <div class="input-wrapper">
        <textarea class="input-text" v-model="url" placeholder="Podaj Url"></textarea>
        <b-button class="generate-button" @click="getDescriptionByUrl()">Pobierz opis</b-button>
        <textarea v-model="description" class="input-text" rows="15" placeholder="podaj opis"></textarea>
        <b-button class="generate-button" @click="sendDescriptionToChatGPT(description)">Generuj tagi</b-button>
      </div>
      
      <div class="generated-tags">
        <div v-if="loading" class="loading-spinner">
          <div class="spinner"></div> 
        </div>

        <div class="tags-section">
          <h2>Tagi PL</h2>
          <div class="tags-list">
            <div v-for="(tag, index) in generated_tags_pl" :key="'pl-' + index" class="tag-item">
              <span class="tag-name">{{ tag.tag }}</span>
              <div class="tag-status-buttons">
                <button v-if="tag.status !== 'Declined'" class="status-button accept" @click="changeTagStatus(tag, 'Accepted')">
                  <i class="fas fa-check-circle"></i>
                </button>
                <button v-else class="status-button edit" @click="changeTagStatus(tag, 'Accepted'); editTag(tag)">
                  <i class="fas fa-check-circle"></i>
                </button>
                <button v-if="tag.status !== 'Declined'" class="status-button decline" @click="changeTagStatus(tag, 'Declined')">
                  <i class="fas fa-times-circle"></i>
                </button>
              </div>
              <input v-if="tag.status === 'Declined'" type="text" v-model="tag.correctTag" placeholder="Wpisz poprawny tag" class="correct-tag-input" />
              <button v-if="tag.status === 'Declined'" class="save-button" @click="saveCorrectTag(tag)">Zapisz poprawiony tag</button>
            </div>
          </div>
        </div>

        <div class="tags-section">
          <h2>Tagi EN</h2>
      <div class="tags-list">
        <div v-for="(tag, index) in generated_tags_en" :key="'en-' + index" class="tag-item">

          <span class="tag-name">{{ tag.tag }}</span>
          <div class="tag-status-buttons">
            <button v-if="tag.status !== 'Declined'" class="status-button accept" @click="changeTagStatus(tag, 'Accepted')">
              <i class="fas fa-check-circle"></i>
            </button>
            <button v-else class="status-button edit" @click="changeTagStatus(tag, 'Accepted'); editTag(tag)">
              <i class="fas fa-check-circle"></i>
            </button>
            <button v-if="tag.status !== 'Declined'" class="status-button decline" @click="changeTagStatus(tag, 'Declined')">
              <i class="fas fa-times-circle"></i>
            </button>
          </div>
          <input v-if="tag.status === 'Declined'" type="text" v-model="tag.correctTag" placeholder="Wpisz poprawny tag" class="correct-tag-input" />
          <button v-if="tag.status === 'Declined'" class="save-button" @click="saveCorrectTag(tag)">Zapisz poprawiony tag</button>
        </div>
      </div>

      <div>
    </div>
        <div class="category-selector">
        <select v-model="selectedCategory" class="category-dropdown">
          <option v-for="(category, index) in categories" :key="index" :value="category.key">
            {{ category.value_pl }}
          </option>
        </select>

        <div v-if="selectedCategory" class="examples-editor">
        <input type="text" class="category-input" placeholder="Dodaj nowy synonim" v-model="newExample" @keyup.enter="addNewExample">
        <button class="add-example-button" @click="addNewExample">Dodaj</button>

        <div class="examples-list">
          <div v-for="(example, index) in examples[selectedCategory]" :key="index" class="example-item">
            {{ example }}
            <button class="delete-example-button" @click="deleteExample(index)">Usuń</button>
          </div>
        </div>

        <button class="submit-synonyms-button" @click="submitSynonyms">Zapisz synonimy</button>
      </div>
    </div>
    </div>
    </div>
    </div>
  </template>
  
  <script>
  import { library } from '@fortawesome/fontawesome-svg-core';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


  library.add(faCheckCircle, faTimesCircle);
  import axios from 'axios';
  import TextareaAutosize from 'vue-textarea-autosize';
  import { computed } from 'vue';

  
  export default {
    name: 'TagsView',
    components: {
      TextareaAutosize,
      FontAwesomeIcon
    },
    
  
    data() {
      return {
        description: '',
        generated_tags_pl: [],
        generated_tags_en: [],
        group_id: 0,
        prompt_id: 0,
        url: '',
        loading: false,
        selectedOption: "pl",
        categories: [],
        selectedCategory: null,
        newExample: '',
        examples: {},
        selectedCategoryName: '',
        options: [
        { label: "Polski", value: "pl" },
        { label: "Angielski", value: "en" },
        { label: "Chiński", value: "cn" },
        { label: "Rosyjski", value: "ru" },
      ],
      }
    },
    watch: {
      selectedCategory(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getSynonyms();
        }
      },
    },
    methods: {
        getSynonyms(){
          axios.get("api/category-synonyms?category=" +this.selectedCategory)
          .then((response) => {
            this.examples[this.selectedCategory] = response.data.synonyms || [];
          })
          .catch(error => console.error("Error: ", error));
      },
      addNewExample() {
      if (this.newExample.trim() !== '') {
        if (!this.examples[this.selectedCategory]) {
          this.examples[this.selectedCategory] = [];
        }

        this.examples[this.selectedCategory].push(this.newExample);
        this.newExample = '';
      }
    },
    deleteExample(index) {
      this.examples[this.selectedCategory].splice(index, 1);
      this.examples[this.selectedCategory] = [...this.examples[this.selectedCategory]];
    },
    submitSynonyms() {
      console.log(this.categories[this.selectedCategory].value_pl)

      axios.post("api/category-synonyms", {'category': this.selectedCategory, 'synonyms': this.examples[this.selectedCategory] , 'category_name' : this.categories[this.selectedCategory].value_pl})
          .then((response) => {
              console.log(response)
          })
    },

      sendDescriptionToChatGPT(description) {
        this.loading = true
        axios.post("api/generate-tags", { description: description, group_id: this.group_id, category_route: this.selectedCategory })
          .then((response) => {
              this.generated_tags_pl = response.data.tags.pl.map(tag => ({
                  tag: tag,
                  status: 'Accepted',
                  correctTag: ''
              }));
              this.generated_tags_en = response.data.tags.en.map(tag => ({
                  tag: tag,
                  status: 'Accepted',
                  correctTag: ''
              }));
            this.prompt_id = response.data.prompt_id
            this.loading = false
          });
      },
      changeTagStatus(tag, status) {
      tag.status = status;
    },
    editTag(tag) {
      tag.correctTag = '';
    },
    saveCorrectTag(tag) {
    this.$swal({
      title: 'Na pewno?',
      text: "Ta poprawka zostanie zapisana",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Wprowadź poprawkę',
      cancelButtonText: 'Zrezygnuj'
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (tag.correctTag) {
            const response = await axios.patch('/api/correct-tags', {
              tag: tag.tag,
              correctTag: tag.correctTag,
              description: this.description,
              prompt_id : this.prompt_id
            });

            if (response.status === 200) {
              this.$swal.fire(
                'Zapisano poprawkę!',
                'Dziękujemy za poprawienie tagu, Te informacje pomogą nam lepiej generować tagi',
                'success'
              )
            } else {
              console.error(response.data);
            }
          }
        }
      });
    },
    getDescriptionByUrl(){
      if(this.url.length > 15) {
        axios.get(
          'api/description', {
            params: {
              url: this.url,
              language: this.selectedOption
            }
          }
        ).then((response) =>{
          this.description = response.data.data
          this.group_id = response.data.group_id
          this.selectedCategory = response.data.category_route
        })
      }
    },
    sendAdditionalInfo(){
      this.$swal({
      title: 'Na pewno?',
      text: "Ta poprawka zostanie zapisana",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Wprowadź poprawkę',
      cancelButtonText: 'Zrezygnuj'
      }).then(async (result) => {
        if (result.isConfirmed) {
      axios.patch(
        'api/additional-info',{
            additional_info: this.additional_info,
            tags: this.generated_tags,
            description: this.description,
            prompt_id: this.prompt_id
          },
          {
          headers: {
            'Content-Type': 'application/json'
          }
        }
          ).then((response) => {
            console.log(response.data)
            if (response.status === 200) {
              this.$swal.fire(
                'Zapisano poprawkę!',
                'Dziękujemy za podanie dodatkowych informacji, Te informacje pomogą nam lepiej generować tagi',
                'success'
              )
            } else {
              console.error(response.data);
            }
          })

          }
      });
    },
    getCategories(){
          axios.get(
            'api/category', {}
          ).then((response) =>{
            this.categories = response.data
          })
      }
      },
    beforeMount() {
      this.getCategories();
  }
}

  </script>

<style src="../style/TagsViewStyle.css" scoped>

category-selector {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.category-dropdown {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}

.examples-editor {
  width: 100%;
}

.category-input, .example-input {
  width: calc(100% - 90px); /* Odejmij szerokość przycisków */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 16px;
}

.add-example-button, .save-example-button, .delete-example-button {
  padding: 8px 12px;
  margin-left: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #007bff;
  color: white;
}

.add-example-button:hover, .save-example-button:hover, .delete-example-button:hover {
  background-color: #0056b3;
}

.delete-example-button {
  background-color: #dc3545;
}

.delete-example-button:hover {
  background-color: #bd2130;
}

.examples-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.example-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
</style>