import { createStore } from 'vuex'

export default createStore({
  state: {
    token: '',
    isAuthenticated: false
  },
  mutations: {
    initializedStore(state){
      if(localStorage.getItem('token')){
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = null,
        state.isAuthenticated = false
      }
    },
    setToken(state, token){
      state.token = token,
      state.isAuthenticated = true
      return state
    },
    removeToken(state){
      state.token = null
      state.isAuthenticated = false
      return state
    }


  },
  actions: {
  },
  modules: {
  }
})
