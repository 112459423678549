import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import TextareaAutosize from 'vue-textarea-autosize';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


var hostname = window.location.hostname
if(hostname.includes('vitkac.sni.pl')){
    axios.defaults.baseURL='https://ai.api.vitkac.sni.pl'    
} else {
    axios.defaults.baseURL='http://0.0.0.0:8000'
}


createApp(App).use(store).use(router, axios).use(VueSweetalert2).mount('#app')
