<template>
  <div id="wrapper">
    <nav class="navbar is-dark"  v-if="this.$store.state.isAuthenticated == true">
        <div class="navbar-brand" >
          <router-link to="/" class="navbar-item"><strong>AI_Management_panel</strong></router-link>

          <a class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu" @click="showMobileMenu = !showMobileMenu">
            <span aria-hidden="false"></span>
            <span aria-hidden="false"></span>
            <!-- <span aria-hidden="false"></span> -->
          </a>
        </div>

        <div class="navbar-menu" id="navbar-menu" v-bind:class="{'is-active': showMobileMenu}">
          <div class="navbar-end">
            <router-link to="/tags" class="navbar-item">Tagi</router-link>
            <router-link to="/translations" class="navbar-item">Tłumaczenia</router-link>
            <!-- <router-link to="/csv-analyzer" class="navbar-item">CSV analyzer</router-link> -->

            <div class="navbar-item">
              <div class="buttons">
                <button @click="logout()" class="button is-light">wyloguj się</button>
              </div>
            </div>
          </div>
        </div>
    </nav>

    <section class="section">
      <router-view/>
    </section>

    <footer class="footer">
      <p class="has-text-centered">Copyright &copy; 2023</p>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';


export default {
  name: 'App',
  beforeCreate(){
    this.$store.commit('InitializeStore')

    const token = this.$store.state.token

    if(token) {
      axios.defaults.headers.common['Authorization'] = 'Token' + token
    } else {
      // axios.defaults.headers.common['Authorization'] = 'Token c2bcba9037aff5f5d1949ee0e56dce11e688061c'
      this.$router.push('/log-in')
    }
  },
  data() {
    return {
      showMobileMenu: false,
    }
  },
  methods: {
    logout() {
      this.$store.commit('removeToken')
      localStorage.removeItem('token');
      this.$router.push('/log-in')
    }
  }
}
</script>

<style lang="scss">
@import '../node_modules/bulma';
</style>
