<template>
    <div class="tags">
      <div class="radio-buttons">
        <div class="radio-buttons-label">Wybierz język:</div>
      <label v-for="option in options" :key="option.value" class="radio-button">
        <input type="radio" :value="option.value" v-model="selectedOption" @change="handleChange" />
        <span class="checkmark"></span>
        {{ option.label }}
      </label>
      </div>

      <div class="input-wrapper">
        <textarea class="input-text" v-model="url" placeholder="Podaj Url"></textarea>
        <b-button class="generate-button" @click="getDescriptionByUrl()">Pobierz opis</b-button>
        <textarea v-model="description" class="input-text" rows="15" placeholder="podaj opis"></textarea>
        <b-button class="generate-button" @click="sendDescriptionToChatGPT(description)">Przetłumacz ten tekst</b-button>
      </div>
      
      <div class="generated-tags">
        <div v-if="loading" class="loading-spinner">
          <div class="spinner"></div> 
        </div>
            {{ this.translated_text }}

            <textarea v-model="correctTranslate" class="input-text" rows="4" placeholder="Jeżeli tłumaczenie zawiera błędy to podaj pełne tłumaczenie jakie powinno być"></textarea>
            <b-button class="generate-button" @click="sendCorrectTranslate()">Zapisz poprawne tłumaczenie</b-button> 
      </div>
      <textarea v-model="additional_info" class="input-text" rows="4" placeholder="Dodaj swoje uwagi lub informacje które pomogą nam polepszyć tłumaczenie opisów"></textarea>
      <b-button class="generate-button" @click="sendAdditionalInfo()">Dodaj dodatkowe informacje</b-button> 
    </div>
  </template>
  
  <script>
  import { library } from '@fortawesome/fontawesome-svg-core';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


  library.add(faCheckCircle, faTimesCircle);
  import axios from 'axios';
  import TextareaAutosize from 'vue-textarea-autosize';

  
  export default {
    name: 'Translations',
    components: {
      TextareaAutosize,
      FontAwesomeIcon
    },
    
  
    data() {
      return {
        description: '',
        translated_text: '',
        prompt_id: 0,
        url: '',
        loading: false,
        selectedOption: "pl",
        options: [
        { label: "Polski", value: "pl" },
        { label: "Angielski", value: "en" },
        { label: "Chiński", value: "cn" },
        { label: "Rosyjski", value: "ru" },
        
      ],
      correctTranslate: '',
      }
    },
    methods: {
      sendDescriptionToChatGPT(description) {
        this.loading = true
        axios.post("api/translations", { description: description,language : this.selectedOption})
          .then((response) => {

          this.translated_text = response.data.translations 
          
          this.prompt_id = response.data.prompt_id
          this.loading = false
          });
      },

    sendCorrectTranslate() {
    this.$swal({
      title: 'Na pewno?',
      text: "Ta poprawka zostanie zapisana",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Wprowadź poprawkę',
      cancelButtonText: 'Zrezygnuj'
      }).then(async (result) => {
        if (result.isConfirmed) {
          
            const response = await axios.patch('/api/correct-translation', {
              translate : this.translated_text,
              correct_translate: this.correctTranslate,
              description: this.description,
              prompt_id: this.prompt_id,
              language : this.selectedOption
            });

            if (response.status === 200) {
              this.$swal.fire(
                'Zapisano poprawkę!',
                'Dziękujemy za poprawienie tłumaczenia, Te informacje pomogą nam lepiej generować tłumaczenia',
                'success'
              )
            } else {
              console.error(response.data);
            }
          
        }
      });
    },
    getDescriptionByUrl(){
      if(this.url.length > 15) {
        axios.get(
          'api/description', {
            params: {
              url: this.url,
              language: this.selectedOption
            }
          }
        ).then((response) =>{
          this.description = response.data.data
        })
      }
    },
    sendAdditionalInfo(){
        this.$swal({
      title: 'Na pewno?',
      text: "Ta poprawka zostanie zapisana",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Wprowadź poprawkę',
      cancelButtonText: 'Zrezygnuj'
      }).then(async (result) => {
        if (result.isConfirmed) {
      axios.patch(
        'api/translations/additional-info',{
            translate : this.translated_text,
            additional_info: this.additional_info,
            description: this.description,
            prompt_id: this.prompt_id,
            language : this.selectedOption
          },
          {
          headers: {
            'Content-Type': 'application/json'
          }
        }
          ).then((response) => {
            console.log(response.data)
            if (response.status === 200) {
              this.$swal.fire(
                'Zapisano poprawkę!',
                'Dziękujemy za wpisanie dodatkowych informacji, Te informacje pomogą nam lepiej generować tłumaczenia',
                'success'
              )
            } else {
              console.error(response.data);
            }
          })
        }
      });
    }
      
    }
  }
  </script>

<style src="../style/TagsViewStyle.css" scoped></style>
