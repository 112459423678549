<template>
    <div id="app">
        <header>
            <h1>Witaj w naszym panelu AI!</h1>
        </header>
        <main>
            <p>Ten panel służy do sprawdzania jak sobie radzi GPT z generowaniem tagów, tłumaczeniem opisów i w przyszłości z wieloma innymi zadaniami</p>
        </main>
        </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
    
  }
}
</script>
