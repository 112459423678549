import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TagsView from '../views/TagsView.vue'
import LogIn from '../views/LogIn.vue'
import PdfReader from '../views/PdfReader.vue'
import Translation from '../views/Translation.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/tags',
    name: 'tags',
    component: TagsView
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  {
  path: '/translations',
  name: 'Translations',
  component: Translation
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
